












































import {Vue, Component} from "vue-property-decorator";
import FriendCommentContentType from "@/views/Friend/FriendComment/components/Content/index";
import {commentType} from "@/views/Friend/FriendComment/FriendComment";
import Storage from "@/util/Storage"
import { computedTime } from "@/util/computedTime"
import {commentGive, DelComment} from "@/views/Friend/FriendComment/Server";
import {FriendSuccessStore} from "@/views/Friend/FriendSuccess/Store";

@Component({name: "FriendCommentContent"})
export default class FriendCommentContent extends Vue implements FriendCommentContentType{
    CartIcon = require("../../../Img/FirendChat.png");
    GiveIcon = require("../../../Img/FirendGive.png");
    NotGiveIcon = require("../../../Img/FirendNotGive.png");
    DelIcon = require("../../../Img/Del.png")
    userId = ""
    active = false
    data:commentType = {}

    mounted(){
        this.userId = Storage.GetData_ && Storage.GetData_("userId")
        let { id,data,friendId } = this.$route.query
        this.data = JSON.parse( data as string )
        if ( Number(this.data.isLike) ){
            this.active = true
        }else{
            this.active = false
        }
    }

    handleRemoveComment(){
        DelComment(this.data.id as number).then(res=>{
            this.$router.go(-1)
        })
    }

    handleComment(){
        let data = FriendSuccessStore.getCommentData
        data.momentsId = this.data.momentsId as number
        data.toId = this.data.id
        data.secondComment = true
        FriendSuccessStore.setCommentData( data )
        FriendSuccessStore.setCommentShow(true)
    }

    handleGive(){
        commentGive(this.data.id as number).then(res=>{
            this.active = !this.active
        })
    }

    get getPhoto(){
        return this.data.headImg || require("@/assets/icon/Basics/default.png")
    }

    get getTime(){
        // eslint-disable-next-line
        // @ts-ignore
        return computedTime(this.data.createTime as string)
    }

    get commentRemoveShow(){
        if ( Boolean( this.data ) ){
            if ( Number( this.data.userId ) === Number( this.userId ) ){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
    }
}
