


















import {Vue, Component} from "vue-property-decorator";
import FriendCommentType from "@/views/Friend/FriendComment/FriendComment";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import FriendContent from "@/views/Friend/FriendComment/components/Content/index.vue"
import FriendCommentFooter from "@/views/Friend/FriendComment/components/Footer/index.vue";
import CommentShow from "@/views/Friend/components/CommentShow/index.vue";
import {FriendSuccessStore} from "@/views/Friend/FriendSuccess/Store";
import ZoomPage from "@/impView/PageSafety";

@Component({name: "FriendComment",components:{ FriendContent,CommentShow,FriendCommentFooter,HeadTop }})
export default class FriendComment extends ZoomPage implements FriendCommentType{
    index = 0
    loadingShow = true

    mounted(){
        this.loadingShow = false
    }

    handleBack(){
        FriendSuccessStore.setCommentShow(false)
        this.$router.go(-1)
    }

    handleGetComment(e:any){
        let { index } = e
        this.index = index
    }
}
