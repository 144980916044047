











































import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import CommentItemType from "@/views/Friend/FriendComment/components/CommentItem/indexType";
import {computedTime} from "@/util/computedTime";
import Storage from "@/util/Storage";
import {commentGive, DelComment} from "@/views/Friend/FriendComment/Server";

@Component({name: "CommentItem"})
export default class CommentItem extends Vue implements CommentItemType {
    CartIcon = require("../../../Img/FirendChat.png");
    GiveIcon = require("../../../Img/FirendGive.png");
    NotGiveIcon = require("../../../Img/FirendNotGive.png");
    DelIcon = require("../../../Img/Del.png")
    active = false
    userId = ""

    mounted() {
        this.userId = Storage.GetData_ && Storage.GetData_("userId")
        this.init()
    }

    init() {
        if (this.getData && Number(this.getData.isLike)) {
            this.active = true
        } else {
            this.active = false
        }
    }

    handleRemoveComment() {
        DelComment(this.getData.id).then(res=>{
            this.$emit("delComment",{ index:this.getIndex })
        })
    }

    handleGive(){
        commentGive(this.getData.id).then(res=>{
            this.active = !this.active
        })
    }

    get getPhoto() {
        return this.getData && this.getData.headImg || require("@/assets/icon/Basics/default.png")
    }

    get getTime() {
        // eslint-disable-next-line
        // @ts-ignore
        return computedTime(this.getData.createTime as string)
    }

    get removeShow() {
        if (Number(this.getData.userId) === Number(this.userId)) {
            return true
        } else {
            return false
        }
    }

    @Prop(Object)
    data!: any
    get getData() {
        return this.data
    }
    @Watch("getData")
    changeData(newVal: any) {
        this.init()
    }

    @Prop(Number)
    index!:number
    get getIndex(){
        return this.index
    }
}
