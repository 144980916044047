

















import {Vue, Component, Watch} from "vue-property-decorator";
import FriendCommentFooterType from "@/views/Friend/FriendComment/components/Footer/indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import {friendSucCommentListType} from "@/Api/Friend/interfaceType";
import CommentItem from "@/views/Friend/FriendComment/components/CommentItem/index.vue";
import {commentList} from "@/views/Friend/FriendComment/Server";


@Component({name: "FriendCommentFooter",components:{ PullDownUpList,ListBottom,CommentItem }})
export default class FriendCommentFooter extends Vue implements FriendCommentFooterType{
    PullDown = false;
    UpDownBool = false;
    upData: friendSucCommentListType = {
        pageNo:1,
        pageSize:15,
        firstCommentId:""
    };
    List:any[] = []
    StopUp = false

    activated(){
        let { id } = this.$route.query
        this.upData.firstCommentId = id as string;
        this.handleUpData()
    }

    handlePullDown(): void {
        //
    }

    handleUpDown(): void {
        if ( this.StopUp )return;
        this.upData.pageNo += 1
        this.handleUpData()
    }

    handleUpData(bool=false): void {
        commentList(this.upData).then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < this.upData.pageSize )this.StopUp = true;
            if ( bool )
                this.List = res;
            else
                this.List = this.List.concat(res);
        })
    }

    handleFilterCommentList(e:any){
        let { index } = e;
        let List = this.List
        List.splice(index,1)
        this.List = List
    }

    @Watch("List")
    changeList(newVal:any[]){
        this.$emit("pullList",{ index:newVal.length })
    }
}
